<template>
	<div class="fz-95">
		<div class="row form-inline justify-content-between">
			<div class="col-2 pr-0">
				<input-quantity
					v-model="busca.quantidade"
					:unidade="'Qtde'"
					:precisao="{ min: 0, max: 6 }"
					:preencherDecimalAutomaticamente="false"
				/>
			</div>
			<div class="col-5 pl-0">
				<div class="form-group">
					<div class="input-group input-group-sm pr-2 pl-1 col-12">
						<input
							id="lmxta-input-codigo-barras-item"
							type="text"
							class="form-control form-control-sm border-right-0 border"
							:placeholder="labelInputProduto"
							@keyup.enter.prevent="buscarItem"
							v-model="busca.codigo"
							autocomplete="nope"
						/>
						<div class="input-group-append">
							<span
								style="cursor: pointer"
								class="input-group-text"
								v-tooltip.bottom="apresentarTooltip('Inserir item na devolução (Enter).')"
								@click="buscarItem"
							>
								<i class="fa fa-plus"></i>
							</span>
						</div>
						<button
							id="button-pessquisar-itens"
							type="button"
							v-tooltip.bottom="apresentarTooltip('Pesquisar itens.')"
							class="btn btn-primary btn-sm float-right"
							@click="buscarPesquisaAvancada"
						>
							<span class="text-center">
								<i class="fas fa-search"></i>
							</span>
						</button>
						<button
							id="button-importar-arquivo-itens"
							class="btn btn-default btn-sm"
							type="button"
							v-tooltip.bottom="apresentarTooltip('Importar arquivo de itens.')"
							@click="importarArquivoItens"
						>
							<span class="text-center">
								<i class="fas fa-upload"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
			<div class="col-5">
				<button
					id="lmxta-identificacao-btn-fornecedor"
					type="button"
					data-toggle="modal"
					data-target="#ModalPesquisaCliente"
					@click="abrirSelecaoFornecedor()"
					class="btn btn-secondary text-center identificacao-cliente-button w-100 form-control-sm"
					data-backdrop="static"
					data-keyboard="false"
					:disabled="notasAssociadas.length > 0"
					v-tooltip.bottom="apresentarTooltip('Escolha o fornecedor para realizar a devolução.')"
				>
					<div class="truncate" v-if="!identificacao.Fornecedor">Informe o fornecedor</div>
					<div class="truncate">{{ nomeFornecedor }}</div>
				</button>
			</div>
		</div>

		<FornecedorSelecao
			v-if="UI.SelecionandoFornecedor"
			@fechar="UI.SelecionandoFornecedor = false"
			@selecionar="selecionarFornecedor"
		/>

		<PesquisaAvancadaProdutos
			v-if="UI.PesquisaAvancada"
			@fechar="fecharPesquisaAvancada"
			@retornarProdutos="inserirProdutosReferenciaEntradas"
		/>

		<UploadArquivosProdutos
			v-if="UI.UploadArquivoProdutos"
			:showFileTypes="true"
			@fechar="fecharModalImportacaoArquivo"
			@retornarProdutos="inserirProdutosReferenciaEntradas"
		/>

		<div class="mt-auto">
			<button
				v-if="produtosAssociacaoManual.length > 0"
				type="button"
				class="btn btn-default btn-sm mt-3"
				:disabled="!algumProdutoSelecionado"
				@click="removerItensSelecionados"
				v-tooltip.bottom="apresentarTooltip('Remover os itens selecionados da devolução.')"
			>
				<i class="fas fa-trash-alt"></i> Remover
			</button>
			<button
				v-if="produtosAssociacaoManual.length > 0 && tipoArquivoReferenciaEntradaSemChaveNFe"
				v-tooltip.bottom="
					apresentarTooltip(
						'Será associada a nota fiscal de entrada mais recente de cada item que não teve associação manual.',
					)
				"
				type="button"
				class="btn btn-success btn-sm mt-3 ml-2"
				@click="iniciarAssociacaoAutomatica"
			>
				<span> <i class="fas fa-magic"></i> Associar Automaticamente </span>
			</button>
			<div class="dropdown" v-if="tipoArquivoReferenciaEntradaSemChaveNFe">
				<button
					class="btn btn-sm btn-primary dropdown-toggle mt-3 ml-1"
					id="idDropdown"
					data-toggle="dropdown"
					data-boundary="viewport"
					aria-haspopup="true"
					aria-expanded="false"
					@click.stop
				>
					Filtrar Produtos Divergentes
				</button>

				<div class="dropdown-content" @click.stop>
					<div class="custom-control custom-checkbox multiple" v-for="(opcao, index) in opcoesDisponiveis" :key="index">
						<input
							class="custom-control-input"
							type="checkbox"
							v-model="idsOpcoesSelecionadas"
							:id="obterIdInputOpcaoFiltro(opcao.Id)"
							:key="index + opcao.Id"
							:value="opcao.Id"
						/>
						<label class="custom-control-label" :for="obterIdInputOpcaoFiltro(opcao.Id)" :key="index">{{
							opcao.Nome
						}}</label>
					</div>
				</div>
			</div>

			<div class="table-responsive height-item my-2">
				<div class="custom-control custom-checkbox" v-if="produtosAssociacaoManual.length > 0">
					<input
						class="custom-control-input"
						type="checkbox"
						id="selecionarProds"
						v-model="todosItensSemVinculoSelecionados"
						@change="selecionarTodosItensSemVinculo($event.target.checked)"
					/>
					<label class="custom-control-label" for="selecionarProds">Selecionar Todos os Produtos</label>
				</div>

				<table v-for="(item, index) in produtosAssociacaoManual" :key="index" class="table table-hover table-sm mb-0">
					<thead>
						<tr style="cursor: pointer" class="tr-group-by">
							<th class="align-middle" style="width: 0%">
								<input id="input-selecionar-produto" type="checkbox" class="pl-2" v-model="item.Selecionado" />
							</th>
							<th class="align-middle lh-sm" :colspan="colspanProduto(item, 5)">
								<strong>
									<span id="span-descricao-produto" class="text-uppercase">
										{{ item.Descricao }}
									</span>
									<span id="span-referencia-produto" class="small pl-1">
										(Ref:{{ item.Referencia }} | Cód:{{ item.CodigoProduto }}).
									</span>
								</strong>
							</th>
							<th id="input-quantidade-produto" class="align-middle text-right" colspan="2">
								<input-quantity
									v-if="tipoArquivoReferenciaEntradaSemChaveNFe"
									class="float-right"
									@alteracao="verificarQuantidade($event, item)"
									v-model="item.Quantidade"
									:unidade="'Qtde'"
									:precisao="item.CasasDecimais"
								/>
								<span v-else class="text-right text-muted w-80">{{ item.Quantidade }} (Qtde)</span>
							</th>
							<th id="th-preco-total-produto" class="align-middle text-right text-nowrap w-250"
								>Valor Total: {{ item.ValorTotal | money }}</th
							>
						</tr>
					</thead>
					<tbody class="fz-85">
						<template v-if="!item.EntradasDisponiveis.length">
							<tr>
								<td :colspan="colspanProduto(item, 9)" class="text-center">
									<img class="lx-icon-md" src="@/assets/Images/linx-icon-ilustrativo/alert.png" alt="alert" />
									<p class="text-uppercase fz-95">Nota entrada não encontrada...</p>
									<p class="text-uppercase fz-95">
										todas as entradas já foram devolvidas
										<br />
										ou não há notas disponíveis para esse fornecedor
									</p>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td></td>
								<td class="text-nowrap pl-4">Número/Série</td>
								<td class="text-nowrap">Data de Emissão</td>
								<td class="text-nowrap">Data Lançamento</td>
								<td class="text-nowrap">CFOP</td>
								<td v-show="notaPossuiControleSerial(item.EntradasDisponiveis)" class="text-nowrap">Serial</td>
								<td class="text-nowrap text-right">Preço Unitário</td>
								<template v-if="notaPossuiControleLote(item.EntradasDisponiveis)">
									<td class="text-nowrap text-center">Lote</td>
									<template v-if="controlaLoteDatas">
										<td class="text-nowrap">Fabricação</td>
										<td class="text-nowrap">Validade</td>
									</template>
								</template>
								<td
									v-if="notaPossuiFatorConversao(item.EntradasDisponiveis)"
									@click="replicarUnidade(item, index)"
									v-tooltip.top="
										apresentarTooltip('Replicar a unidade da primeira linha para todos os produtos da nota.')
									"
									class="text-nowrap text-center"
									style="cursor: pointer"
									>Unidade
									<i class="fas fa-clone"></i>
								</td>
								<td class="text-nowrap text-right">Disponível</td>
								<td class="text-nowrap text-right w-80">Selecionada ({{ item.QuantidadeSelecionada }})</td>
								<td class="text-nowrap text-right w-250">Preço Total</td>
							</tr>
							<tr
								v-for="(nota, indexNota) in item.EntradasDisponiveis"
								:key="indexNota"
								style="cursor: pointer"
								@click.prevent="alterarSelecionarItem(nota, item)"
								:class="{ 'bg-secondary': nota.Selecionado }"
							>
								<td></td>
								<td id="td-numero-serie-nota" class="align-middle pl-4">
									<a @click="abrirDocumentoInterno(nota.Id)" href="#">{{ nota.Numero }} / {{ nota.Serie }}</a>
								</td>
								<td id="td-data-emissao" class="align-middle">{{ nota.DataEmissao | date }}</td>
								<td id="td-data-lancamento" class="align-middle">{{ nota.DataLancamento | date }}</td>
								<td id="td-cfop" class="align-middle">{{ nota.Cfop }}</td>
								<td
									v-show="notaPossuiControleSerial(item.EntradasDisponiveis)"
									id="td-serial"
									class="align-middle"
									>{{ nota.Serial }}</td
								>
								<td id="td-preco-unitario" class="align-middle text-right">{{ nota.PrecoUnitario | money }}</td>

								<template v-if="notaPossuiControleLote(item.EntradasDisponiveis)">
									<td id="td-lote" class="text-center align-middle">{{ nota.Lote }}</td>
									<template v-if="controlaLoteDatas">
										<td id="td-lote-data-fabricacao" class="align-middle">{{
											nota.LoteDataFabricacao | date
										}}</td>
										<td id="td-lote-data-validade" class="align-middle">{{
											nota.LoteDataVencimento | date
										}}</td>
									</template>
								</template>

								<td v-if="permiteAlterarUnidade(nota)" class="align-middle">
									<div class="d-flex justify-content-center">
										<select
											v-model="nota.UnidadeSelecionada"
											@change="
												alterarUnidadeNotaProduto(
													index,
													indexNota,
													$event.target.selectedOptions[0]._value,
												)
											"
											:disabled="desabilitaInputUnidade(nota) || desabilitarInputUnidadeCompra(nota)"
											v-tooltip.bottom="
												(desabilitaInputUnidade(item) &&
													apresentarTooltip('Produto não possui unidade de compra cadastrada.')) ||
												(desabilitarInputUnidadeCompra(nota) &&
													apresentarTooltip(
														`A devolução em ${nota.UnidadeCompra?.Descricao} só é possível quando a quantidade disponível for igual ou superior a ${nota.UnidadeCompra?.Descricao} completa.`,
													))
											"
											class="form-control form-control-sm w-250"
											:id="'unidadeSelect_' + nota.Transacao"
											@click.stop
										>
											<option :value="nota.UnidadeCompra" :key="nota.UnidadeCompra?.Id"
												>Unidade de compra - {{ nota.UnidadeCompra?.Descricao }}</option
											>
											<option selected :value="nota.UnidadeVenda" :key="nota.UnidadeVenda.Id"
												>Unidade de venda - {{ nota.UnidadeVenda.Descricao }}</option
											>
										</select>
									</div>
								</td>

								<td id="td-quantidade-pendente" class="align-middle text-right">{{ nota.QuantidadePendente }}</td>
								<td id="td-quantidade-selecionada" class="align-middle text-right input-size">
									<input-quantity
										v-if="permiteAlterarQuantidade(item)"
										class="float-right"
										@input="alterarNotaQuantidade($event, nota)"
										v-model="nota.QuantidadeSelecionada"
										:unidade="nota.UnidadeSelecionada.Descricao"
										:faixa-valor="{ min: 0 }"
										:precisao="nota.UnidadeSelecionada.CasasDecimais"
									></input-quantity>
									<span v-else class="w-80">{{ nota.QuantidadeSelecionada }}</span>
								</td>
								<td id="td-preco-total" class="align-middle text-right w-250">{{
									nota.PrecoTotal | money | hifenDefault
								}}</td>
							</tr>
						</template>
					</tbody>
				</table>
				<div class="h-100 w-100 lmx-tela-relatorio-div-nenhum-registro" v-if="produtosAssociacaoManual.length == 0">
					<div class="text-center" style="margin-top: 150px">
						<i class="fa fa-exclamation text-primary fa-2x"></i>
						<h3>Nenhum registro encontrado</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-between fixed-bottom col-12 pb-1">
			<button id="button-cancelar" type="button" class="btn btn-default col-3" @click="cancelar">
				<i class="fas fa-trash-alt"></i>
				Cancelar
			</button>
			<div>
				<span class="text-uppercase small" style="cursor: default">
					{{ totalizador.Inseridos }} itens inseridos | {{ totalizador.QuantidadeItens }} unidades
				</span>
			</div>
			<button id="button-devolver" type="button" class="btn btn-primary col-3" @click="devolver">Devolver</button>
		</div>
	</div>
</template>

<script>
	import InputQuantity from '@/components/shared/input/InputQuantity.vue';
	import loading from '@/common/loading';
	import FornecedorSelecao from '@/components/shared/crm/FornecedorSelecao.vue';
	import PesquisaAvancadaProdutos from '@/components/shared/produto/PesquisaAvancadaProdutos.vue';
	import UploadArquivosProdutos from '@/components/shared/produto/UploadArquivosProdutos.vue';
	import { DEVOLUCAO_CODIGOS_ARQUIVO_UPLOAD } from '@/core/enums/devolucao-tipos-arquivo-upload.enum';
	import { ID_UNIDADE } from '@/core/enums/unidade-compra-venda.enum';
	import swal from '@/common/alerts';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';
	import { mapState, mapGetters } from 'vuex';

	Vue.use(VTooltip);

	Vue.filter('hifenDefault', function (value) {
		var sanitazedValue = value;

		if (isNaN(sanitazedValue)) {
			var regex = /[+-]?\d+(\.\d+)?/g;
			sanitazedValue = value
				.replace(/,/g, '.')
				.match(regex)
				.map(function (v) {
					return parseFloat(v);
				})[0];
		}

		if (isNaN(sanitazedValue)) return value;

		if (!sanitazedValue) return '-';

		return value;
	});

	export default {
		name: 'DevolucaoPorItens',

		components: {
			InputQuantity,
			FornecedorSelecao,
			PesquisaAvancadaProdutos,
			UploadArquivosProdutos,
		},

		mounted() {
			setTimeout(() => {
				document.querySelector('#lmxta-input-codigo-barras-item').focus();
			}, 300);
		},

		data() {
			return {
				busca: {
					codigo: '',
					quantidade: 1,
				},
				UI: {
					SelecionandoFornecedor: false,
					PesquisaAvancada: false,
					UploadArquivoProdutos: false,
					Modal: {
						AlterarQuantidade: false,
					},
					AssociarErro: '',
				},
				mostrar: true,
				qtdeDivergentes: false,
				prodsSemNota: false,
				todosItensSemVinculoSelecionados: false,
				opcoesDisponiveis: [
					{ Id: 1, Nome: 'Quantidade de produtos divergentes' },
					{ Id: 2, Nome: 'Produtos sem nota fiscal' },
				],

				idsOpcoesSelecionadas: [],
				tiposArquivosUpload: DEVOLUCAO_CODIGOS_ARQUIVO_UPLOAD,
			};
		},

		watch: {
			idsOpcoesSelecionadas: function () {
				if (this.idsOpcoesSelecionadas.length > 0) {
					this.$store.dispatch('devolucao/filtrarProdutos', this.idsOpcoesSelecionadas);
				} else {
					this.$store.dispatch('devolucao/listaDeProdutos');
				}
			},
		},

		computed: {
			...mapState({
				identificacao: (state) => state.devolucao.Identificacao,
				notasAssociadas: (state) => state.devolucao.NotasAssociadas,
				pesquisaProdutoAceitaAlfanumerico: (state) =>
					state.devolucao.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico,
				pesquisaProdutoPorSerial: (state) => state.devolucao.Configuracoes.PesquisaProdutoPorSerial,
				produtos: (state) => state.devolucao.Produtos,
				dadosEmpresa: (state) => state.autenticacao.empresa,
				produtosAssociacaoManual: (state) => state.devolucao.ProdutosAssociacaoManual,
				produtosSemNota: (state) => state.devolucao.produtosSemNota,
				produtosComNota: (state) => state.devolucao.produtosComNota,
				preferenciaEmpresa: (state) => state.devolucao.Configuracoes.preferenciasEmpresa,
				controlaLoteDatas: (state) => state.devolucao.Configuracoes.ControlaLoteDatas,
				controlaLote: (state) => state.devolucao.Configuracoes.ControlaLote,
			}),
			...mapGetters('devolucao', ['baseUrlErp', 'tipoArquivoReferenciaEntrada']),

			urlDocumentoInterno() {
				return this.baseUrlErp + '/faturamento/imprime_doc.asp?identificador=';
			},

			produtosAssociacaoManualSelecionado() {
				return this.produtosAssociacaoManual.filter((p) => p.Selecionado);
			},

			algumProdutoSelecionado() {
				return this.produtosAssociacaoManualSelecionado.length;
			},

			nomeFornecedor() {
				if (this.identificacao.Fornecedor) {
					return this.identificacao.Fornecedor.Nome;
				}

				return '';
			},

			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},

			totalizador() {
				let totalizador = {
					Inseridos: 0,
					QuantidadeItens: 0,
					Selecionados: 0,
				};
				totalizador.Inseridos = this.produtosAssociacaoManual.length;
				if (this.produtosAssociacaoManual.length > 0) {
					this.produtosAssociacaoManual.forEach((prod) => {
						totalizador.QuantidadeItens += prod.Quantidade;
					});
				} else {
					this.notasAssociadas.forEach((nota) => {
						nota.Itens.forEach((item) => {
							totalizador.Inseridos++;
							totalizador.QuantidadeItens += item.Quantidade;

							if (item.Selecionado) totalizador.Selecionados++;
						});
					});
				}
				return totalizador;
			},

			possuiNotasAssociadas() {
				return this.notasAssociadas && this.notasAssociadas.length > 0;
			},
			tipoArquivoReferenciaEntradaSemChaveNFe() {
				//Para o Layout com ChaveNFe, não é permitido alterar a quantidade nem fazer associações de notas.
				let _tipo = this.tipoArquivoReferenciaEntrada;
				return (
					_tipo != this.tiposArquivosUpload.CodigoQuantidadeChaveNF.Id &&
					_tipo != this.tiposArquivosUpload.CodBarrasQuantidadeChaveNF.Id &&
					_tipo != this.tiposArquivosUpload.SerialQuantidadeChaveNF.Id &&
					_tipo != this.tiposArquivosUpload.CodAuxiliarQuantidadeChaveNF.Id
				);
			},

			labelInputProduto() {
				if (this.controlaLote) return 'Informe o código de barras, código do produto, serial ou lote';

				return 'Informe o código de barras, código do produto ou serial';
			},
		},

		methods: {
			permiteAlterarQuantidade(item) {
				return this.tipoArquivoReferenciaEntradaSemChaveNFe || item.EntradasDisponiveis.length > 1;
			},
			alterarSelecionarItem(nota, item) {
				if (!this.tipoArquivoReferenciaEntradaSemChaveNFe) return;

				var quantidade = !nota.Selecionado ? item.QuantidadeRestante : 0;

				if (item.QuantidadeRestante == 0 && !nota.Selecionado)
					swal.exibirToastAlerta('Toda a quantidade para devolução já foi selecionada');

				nota.AlterarQuantidade(quantidade);
			},
			alterarUnidadeNotaProduto(indiceProduto, indiceNota, unidade) {
				this.$store.commit('devolucao/alterarUnidadeNotaProdutoAssociacaoManual', { indiceProduto, indiceNota, unidade });
			},
			verificarQuantidade(novoValor, item) {
				var valor = novoValor.new + -1 * item.QuantidadeRestante;

				if (item.QuantidadeRestante < 0 && novoValor.new > 0) {
					swal.exibirPergunta(
						'',
						'Ao diminuir a quantidade será perdido as notas já selecionadas, deseja continuar?',
					).then((response) => {
						if (response) {
							item.EntradasDisponiveis.forEach((nota) => nota.AlterarQuantidade(0));
							return;
						}
						item.Quantidade = valor;
					});
				}
				return;
			},
			alterarNotaQuantidade(quantidade, nota) {
				this.$nextTick(() => {
					nota.AlterarQuantidade(quantidade);
				});
			},
			//helper?
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},

			abrirDocumentoInterno(identificador) {
				window.open(this.urlDocumentoInterno + identificador, '_blank');
			},

			buscarItem() {
				if (!this.busca.codigo) return;

				if (this.incrementarQuantidadeItemJaExistente(this.busca.codigo, this.busca.quantidade)) {
					this.reiniciarPesquisaPorCodigoBarras();
					return;
				}

				var parametrosObterItem = {
					IdEmpresa: this.dadosEmpresa.Id,
					IdFornecedor: this.identificacao.Fornecedor.Codigo,
					Codigo: this.busca.codigo,
					Quantidade: this.busca.quantidade,
					AceitaCodigoAlfanumerico: this.pesquisaProdutoAceitaAlfanumerico,
					PesquisarProdutosPorSerial: this.pesquisaProdutoPorSerial,
				};

				loading.exibir();
				this.$store
					.dispatch('devolucao/ObterProdutoReferenciaEntradas', parametrosObterItem)
					.then((response) => {
						if (!response.data || !response.data.CodigoProduto) {
							loading.ocultar();
							swal.exibirMensagemAlerta(`Produto '${this.busca.codigo}' não encontrado`);
							this.reiniciarPesquisaPorCodigoBarras();
							return;
						}

						this.reiniciarPesquisaPorCodigoBarras();
						loading.ocultar();
					})
					.catch(() => {
						loading.ocultar();
						swal.exibirMensagemErro('Ocorreu um problema');
						return;
					});
			},

			buscarPesquisaAvancada() {
				this.UI.PesquisaAvancada = true;
			},

			fecharPesquisaAvancada() {
				this.UI.PesquisaAvancada = false;
			},

			importarArquivoItens() {
				this.UI.UploadArquivoProdutos = true;
			},

			//metodo interno, como melhorar segregação? migrar para um método de produto?
			//sou contra um método de produto considerando que a chamada desse método é considerando que no dado momento não sei se o produto já existe ou não
			incrementarQuantidadeItemJaExistente(codigoItem, quantidade, serial) {
				var itemExistente = this.produtosAssociacaoManual.find(function (itemJaInserido, indice) {
					return (
						(itemJaInserido.CodigoProduto == codigoItem || itemJaInserido.CodigoBarras == codigoItem) &&
						!itemJaInserido.Lote
					);
				});

				if (itemExistente && itemExistente.Serial == serial && this.tipoArquivoReferenciaEntradaSemChaveNFe) {
					itemExistente.Quantidade = itemExistente.Quantidade + quantidade;
					return true;
				}
				return false;
			},

			inserirProdutosReferenciaEntradas(dadosProdutosArquivo) {
				var self = this;
				loading.exibir();
				this.fecharModalImportacaoArquivo();
				this.fecharPesquisaAvancada();
				dadosProdutosArquivo.forEach(function (item) {
					self.inserirItensReferenciaEntradas(item);
				});
				loading.ocultar();
			},

			inserirItensReferenciaEntradas(produto) {
				if (this.incrementarQuantidadeItemJaExistente(produto.CodigoProduto, produto.Quantidade, produto.Serial)) return;

				//Caso o layout do arquivo mande a Chave NFe, a quantidade é preenchida automaticamente
				if (!this.tipoArquivoReferenciaEntradaSemChaveNFe) {
					produto.EntradasDisponiveis.forEach((nota) => this.alterarNotaQuantidade(produto.Quantidade, nota));
				}
				this.$store.dispatch('devolucao/inserirProdutoAssociacaoManual', produto);
			},

			fecharModalImportacaoArquivo() {
				this.UI.UploadArquivoProdutos = false;
			},

			reiniciarPesquisaPorCodigoBarras() {
				this.busca.codigo = '';
				this.busca.quantidade = 1;
				this.fecharPesquisaAvancada();
				this.fecharModalImportacaoArquivo();
			},

			removerItensSelecionados() {
				let produtos = this.produtosAssociacaoManual.filter((i) => i.Selecionado);
				this.$store.dispatch('devolucao/removerProdutosAssociacaoManual', produtos);
				if (this.prodsSemNota && this.produtosAssociacaoManual.length == 0) {
					this.prodsSemNota = false;
					this.todosItensSemVinculoSelecionados = false;
					this.$store.dispatch('devolucao/retornarProdutosFiltrados');
				}

				if (this.qtdeDivergentes && this.produtosAssociacaoManual.length == 0) {
					this.qtdeDivergentes = false;
					this.$store.dispatch('devolucao/retornarProdutosFiltrados');
				}
			},

			abrirSelecaoFornecedor() {
				this.UI.SelecionandoFornecedor = true;
			},

			selecionarFornecedor(fornecedor) {
				if (this.identificacao.Fornecedor?.Codigo == fornecedor.Codigo) return;

				this.$store.dispatch('devolucao/atualizarFornecedor', fornecedor);
				loading.exibir();
				this.$store.dispatch('devolucao/recarregarProdutosAssociacaoManual').finally(() => loading.ocultar());
			},

			cancelar() {
				swal.exibirPergunta('', 'Deseja realmente cancelar esta devolução?', async (response) => {
					if (response) {
						this.$store.dispatch('devolucao/iniciarNovaDevolucao');
						this.$router.push({ name: 'DevolucaoIdentificacao' });
					}
				});
			},

			iniciarAssociacaoAutomatica() {
				if (!this.validarFornecedor()) {
					return;
				}

				loading.exibir();
				this.$store.dispatch('devolucao/removerTodasNotasAssociadas');
				this.$store.dispatch('devolucao/alterarProdutoNotaAssociada');

				this.$store
					.dispatch('devolucao/associarAutomaticamente')
					.then((response) => {
						if (!response.data) {
							loading.ocultar();
							swal.exibirMensagemAlerta(
								'Nenhuma nota de entrada encontrada para realizar a associação de itens para devolução.<br/><br/>Lembre-se: Apenas os produtos cuja a nota de entrada foi lançada pelas <i>rotinas de entrada via XML</i> poderão ser devolvidas por esta solução.',
							);
							return;
						}

						// TODO: Verificar e melhorar os retornos de erros possíveis do ES
						if (response.data.error) {
							loading.ocultar();
							// swal.exibirMensagemAlerta(response.data.error);
							return;
						}

						loading.ocultar();
					})
					.catch((error) => {
						loading.ocultar();
						swal.exibirMensagemErro('Ocorreu um problema');
						return;
					});
			},

			devolver() {
				if (!this.validarFornecedor()) {
					return;
				}
				if (this.idsOpcoesSelecionadas.length > 0) {
					this.$store.dispatch('devolucao/listaDeProdutos');
				}
				var produtosQuantidadeInvalida = this.produtosAssociacaoManual.filter((p) => p.QuantidadeRestante != 0);

				var produtosSemNota = this.produtosAssociacaoManual.filter((p) => p.EntradasDisponiveis.length == 0);
				if (produtosSemNota.length) {
					const msg = produtosQuantidadeInvalida
						.map((p) => `O produto  <strong>${p.Descricao}<strong> não possui nota associada.`)
						.join('<br>');
					swal.exibirMensagemAlerta(msg);
					return;
				}
				if (produtosQuantidadeInvalida.length) {
					const msg = produtosQuantidadeInvalida
						.map((p) => `Quantidade selecionada do produto <strong>${p.Descricao}</strong>  é inválida.`)
						.join('<br>');
					swal.exibirMensagemAlerta(msg);
					return;
				}

				this.$store.dispatch('devolucao/removerTodasNotasAssociadas');
				this.$store.dispatch('devolucao/alterarProdutoNotaAssociada');

				if (!this.notasAssociadas || this.notasAssociadas.length <= 0) {
					swal.exibirMensagemAlerta(
						'É necessário inserir itens e associá-los a notas de origem para realizar a devolução.',
					);
					return;
				}

				let notasQuantidadeLimite = this.notasAssociadas
					.map((nota) => {
						let itensQuantidadeLimite = nota.Itens.filter((item) => item.Quantidade > item.QuantidadeLimite);
						return { documento: nota.Numero, serie: nota.Serie, itens: itensQuantidadeLimite };
					})
					.filter((notas) => {
						return notas.itens.length > 0;
					});

				if (notasQuantidadeLimite.length > 0) {
					let mensagem = '';
					notasQuantidadeLimite.forEach(
						(nota) =>
							(mensagem +=
								nota.itens.length > 1
									? `<br> Os produtos ${nota.itens.flatMap((item) => item.CodigoProduto).join(',')}
			 			 da nota ${nota.documento}/${nota.serie} estão com a quantidade selecionada maior que a disponível.`
									: `<br> O produto ${nota.itens.flatMap((item) => item.CodigoProduto).join(',')}
			 			 da nota ${nota.documento}/${nota.serie} está com a quantidade selecionada maior que a disponível.`),
					);
					swal.exibirMensagem(mensagem, 3, null, null, null, { width: '60em' });
					return;
				}

				if (this.preferenciaEmpresa.EmitirNotasPorChaveOrigem) {
					this.$router.push('conferenciaNotas');
				} else {
					this.$router.push('conferencia');
				}
			},

			validarFornecedor() {
				if (!this.identificacao.Fornecedor) {
					this.abrirSelecaoFornecedor();
					return false;
				}

				return true;
			},

			selecionarTodosItensSemVinculo(selecionado) {
				let itens = this.produtosAssociacaoManual.map((item) => {
					item.Selecionado = selecionado;
					return item;
				});

				this.$store.dispatch('devolucao/atualizarTodosItensSemVinculo', itens);
			},

			obterIdInputOpcaoFiltro: function (idOpcao) {
				var self = this;
				return 'opcaoFiltro' + self.command + idOpcao;
			},

			notaPossuiControleSerial(entradasDisponiveis) {
				return entradasDisponiveis.some((nota) => nota.Serial);
			},

			notaPossuiControleLote(entradasDisponiveis) {
				return this.controlaLote && entradasDisponiveis.some((nota) => nota.Lote);
			},

			notaPossuiFatorConversao(entradasDisponiveis) {
				return entradasDisponiveis.some((nota) => this.permiteAlterarUnidade(nota));
			},

			permiteAlterarUnidade(nota) {
				return nota.FatorConversao > 1 && !nota.Serial;
			},

			desabilitaInputUnidade(nota) {
				return !nota.UnidadeCompra;
			},
			desabilitarInputUnidadeCompra(nota) {
				return nota.UnidadeCompra?.QuantidadePendente <= 0;
			},

			colspanProduto(produto, colspan) {
				if (this.notaPossuiControleSerial(produto.EntradasDisponiveis)) colspan++;

				if (this.notaPossuiControleLote(produto.EntradasDisponiveis)) {
					colspan++;

					if (this.controlaLoteDatas) colspan += 2;
				}

				if (this.notaPossuiFatorConversao(produto.EntradasDisponiveis)) colspan++;

				return colspan;
			},

			replicarUnidade(produto, indiceProduto) {
				const primeiroItem = produto.EntradasDisponiveis[0];
				const ehUnidadeCompra = primeiroItem.UnidadeSelecionada.Id == ID_UNIDADE.Compra;

				produto.EntradasDisponiveis.forEach((item, indiceItem) => {
					if ((ehUnidadeCompra && !item.UnidadeCompra) || this.desabilitarInputUnidadeCompra(item)) return;

					const unidade = ehUnidadeCompra ? item.UnidadeCompra : item.UnidadeVenda;
					this.alterarUnidadeNotaProduto(indiceProduto, indiceItem, unidade);
				});
			},
		},
	};
</script>

<style scoped>
	.tr-group-by {
		line-height: 90% !important;
		background-color: #d9d9d9 !important;
	}

	.height-item {
		height: calc(100vh - 250px);
	}

	.input-size {
		max-width: 12rem;
	}

	.w-80 {
		width: 80px;
	}

	.w-130 {
		width: 130px;
	}

	.w-190 {
		width: 190px;
	}

	.w-250 {
		width: 250px;
	}

	.table-hover tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.lh-sm {
		line-height: 1.25 !important;
	}
	.dropdown {
		position: relative;
		display: inline-block;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		padding: 12px 16px;
		z-index: 1;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}
</style>
